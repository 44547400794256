import merge from 'lodash/merge';

import ErrorReporter from '@peakon/error-reporter';

import { getBuffer } from './lib/middlewares';

// Unset the default window error as Rollbar should take that job now
window.onerror = null;

const errorReporter = new ErrorReporter({
  accessToken: ENV.rollbar ? ENV.rollbar.client : '',
  environment: ENV.environment,
  version: ENV.version,
  transform: (payload) => {
    // https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
    const trace = payload.body.trace;
    const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+peakon\.com(.*)/;
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const filename = trace.frames[i].filename;
        if (filename) {
          const m = filename.match(locRegex);
          // Be sure that the minified_url when uploading includes 'dynamichost'
          trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`;
        }
      }
    }

    merge(payload, {
      data: {
        custom: {
          previousActions: getBuffer(),
        },
      },
    });
  },
});

window.errorReporter = errorReporter;

export default errorReporter;
