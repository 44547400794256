import React, { Fragment, useState } from 'react';

import removeMarkdown from 'markdown-to-text';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- disabling the rule in existing code to be able to enable the rule globally
import { useTranslation } from 'react-i18next';

import { UnstyledButton } from '@peakon/bedrock/react/button';
import { SlideIn, Typography } from '@peakon/components';

import styles from './ValueCard.scss';
import { MarkdownWrapper } from '../MarkdownWrapper/MarkdownWrapper';

function ValueCard({ isMobile, question, slideInProps = {} }) {
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);

  const maxDescriptionLength = isMobile ? 140 : 180;
  const ALLOWED_TAGS = ['p', 'strong', 'ul', 'li'];

  return (
    <Fragment>
      <div className={styles.root}>
        {removeMarkdown(question.value.description).length >
        maxDescriptionLength ? (
          <Fragment>
            <MarkdownWrapper
              source={question.value.description}
              // NOTE: cut it shorter than maxDescriptionLength so we have 3rd line free for the read more link
              // NOTE: the html-ellipsis library will remove one character from the limit when appending the ellipsis
              truncate={isMobile ? 91 : 141}
              allowedTags={ALLOWED_TAGS}
            />
            <UnstyledButton
              className={styles.readMore}
              data-test-id="value-read-more-btn"
              onClick={() => setShowDescription(true)}
              accessibleName={t('values_card__read_more_ext', {
                valueName: question.value.name,
              })}
            >
              {t('values_card__read_more_ext', {
                valueName: question.value.name,
              })}
            </UnstyledButton>
          </Fragment>
        ) : (
          <MarkdownWrapper
            source={question.value.description}
            allowedTags={ALLOWED_TAGS}
          />
        )}
      </div>
      <SlideIn
        show={showDescription}
        title={t('values_card__slide_in_title', {
          questionText: question.text,
        })}
        closeButtonLabel={t('common_close')}
        onClose={() => setShowDescription(false)}
        {...slideInProps}
      >
        <SlideIn.Panel>
          <SlideIn.Content>
            <Typography.H2>
              {t('values_card__title', { valueName: question.value.name })}
            </Typography.H2>
            <MarkdownWrapper
              source={question.value.description}
              allowedTags={ALLOWED_TAGS}
            />
          </SlideIn.Content>
        </SlideIn.Panel>
      </SlideIn>
    </Fragment>
  );
}

ValueCard.propTypes = {
  isMobile: PropTypes.bool,
  question: PropTypes.object.isRequired,
  slideInProps: PropTypes.object,
};

export default ValueCard;
