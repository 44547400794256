import React from 'react';

import htmlEllipsis from 'html-ellipsis';
import PropTypes from 'prop-types';
import Markdown from 'react-remarkable';
import DOMPurify from 'dompurify';

export function MarkdownWrapper({
  source,
  allowedTags = [],
  openLinksInNewTab = false,
  options,
  truncate,
}) {
  return (
    <Markdown
      source={source}
      sanitize={(rendered) => {
        const html = truncate
          ? htmlEllipsis(rendered, truncate, true)
          : rendered;

        DOMPurify.addHook(
          'afterSanitizeAttributes',
          function (currentNode, hookEvent, config) {
            if (currentNode.nodeName === 'A') {
              if (
                allowedTags?.includes('a') &&
                currentNode.hasAttributes('href')
              ) {
                if (openLinksInNewTab) {
                  currentNode.setAttribute('target', '_blank');
                }
              } else {
                currentNode = null;
              }
            }
          },
        );

        return DOMPurify.sanitize(html, { ALLOWED_TAGS: allowedTags });
      }}
      options={{
        breaks: true,
        html: false,
        ...options,
      }}
    />
  );
}
MarkdownWrapper.propTypes = {
  source: PropTypes.string,
  allowedTags: PropTypes.array,
  openLinksInNewTab: PropTypes.bool,
  options: PropTypes.object,
  truncate: PropTypes.number,
};
